import React from 'react';
import err from '../assets/error.svg'
import {Link} from "react-router-dom";
const ErrorPage = () => {
    return (
        <div className={"pd2"}>
           <div className="center-all centerText position_rel">
               <div className="width35 center-all ">
                   <img alt={'error pic'} src={err} className={'fluidImg '} />
               </div>
               <h6 className="h5 mgT4">Oh no! 😢😢 page not found </h6>
               <p className="h7 mgT2">Please go back to <Link to={'/'} className="link">Homepage</Link></p>
           </div>
        </div>
    );
};

export default ErrorPage;