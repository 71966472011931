import React,{memo} from 'react';
import  {Link} from "react-router-dom"
const ProjectCard = ({img,title,link, cat,date}) => {

    return (
        <Link to={link} className="project ">
            <div className="project-img">
                <img src={img} alt="" className={"fluidImg"}/>
            </div>
            <h2 className="h4 mgT2">{title}</h2>
            <div className="project-view">

                    <p className="h5 purple">{cat}</p>
                    <h6 className="h5 purple">{date}</h6>

            </div>

        </Link>
    );
};

export default memo(ProjectCard);