import React, {useState,useEffect} from 'react';
import Navigation from "../../components/navigation";
// import SocialIconBox from "../../components/socialIcons";
import Tab from "../../components/tab";
import ProjectCard from "./ProjectCard";
import Footer from "../../components/Footer";
import Loader, {useLoading} from "../../components/Loader";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import AnimatedPage from "../../components/AnimatedPage";
import {getAll} from "../../helpers/api/portfolio";
import {BiMessage} from "react-icons/bi";
import moment from "moment";
const base = "";
function Projects() {
    const [index,setIndex] = useState(0);
    const [tabs] = useState([<AllProjects/>,<Design/>,<Development/>])
    const {loading} = useLoading(2000)
    useScrollToTop()
    return (
        <div className='container'>

            {
                loading?<Loader title={"Work."}/>:null
            }
            <AnimatedPage>
                <Navigation/>
                <div className="projects-area">
                    <h2 className="h2 mg-b4">
                        My Portfolio
                    </h2>
                    <div className="mgT4">
                        <Tab tabs={["All","Design","Development"]} activeIndex={index} onChange={setIndex} tabsBody={tabs[index]}/>
                    </div>
                </div>


                {/*<SocialIconBox/>*/}
                <Footer/>
            </AnimatedPage>
        </div>
    )
}

function AllProjects() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("All").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data?.length>0?data.map((e)=> <ProjectCard title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={moment(e.date).format("YYYY")}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                    <p className="h2 purple"><BiMessage/></p>
                    <p className="h5 mgT2 purple">Nothing Found</p>
                </div>
        }
    </div>
}

function Design() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("Design").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data?.length>0?data.map((e)=> <ProjectCard title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={"2021"}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                    <p className="h2 purple"><BiMessage/></p>
                    <p className="h5 mgT2 purple">Nothing Found</p>
                </div>
        }
    </div>
}

function Development() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("Dev").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data?.length>0?data.map((e)=> <ProjectCard title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={"2021"}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                    <p className="h2 purple"><BiMessage/></p>
                    <p className="h5 mgT2 purple">Nothing Found</p>
                </div>
        }
    </div>
}

export default Projects;