import React from 'react';
import {Link} from "react-router-dom";
import Logo from '../assets/logo.svg';
function Navigation() {
    return (
        <nav className="top-nav">
            <Link to='/' className="logo-link">
                <figure>
                    <img className="top-logo" src={Logo} alt="okechukwu's logo"/>
                </figure>
            </Link>
            <input type="checkbox" id="menu"/>
            <label htmlFor="menu" className="menu-box">
                <span className="menu">&nbsp;</span>
            </label>
            <ul className="top-nav-list">
                <li className="top-nav-list--item"><Link to='/work' className="top-nav-list--item--link">Work</Link></li>
                <li className="top-nav-list--item"><Link to="/about" className="top-nav-list--item--link">About</Link></li>
                {/*<li className="top-nav-list--item"><Link  to='/#testimonials' className="top-nav-list--item--link">Testimonials</Link></li>*/}
                <li className="top-nav-list--item"><Link to="/contact" className="btn btn-purple">Reach Out <span style={{fontSize:"2.4rem"}}> 👋</span></Link></li>

            </ul>
        </nav>

    );
}

export default Navigation;