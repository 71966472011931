import React from 'react';
import avatar from '../../assets/avatar.JPG'
import GradientCircle from "../../img/GradientCircle";
import {BiCurrentLocation} from "react-icons/bi";
import {motion} from "framer-motion";
import AnimatedText from "../../components/animatedText";

const Hero = () => {
    return (
        <div className={'hero-section'} >
            <div className="grid center-all">
                    <div className="grid-item2_4">
                        <p className="h4 purple f2"><span style={{whiteSpace:"nowrap"}}>Hi 👋, I’m Okechukwu</span></p>
                        <AnimatedText><h1 className="major-h"><span style={{whiteSpace:"nowrap"}}>UI DESIGNER</span>
                           <span style={{display:"block",whiteSpace:"nowrap"}}> & DEVELOPER</span></h1></AnimatedText>
                        <div className="mgT8 display_flex align-center  width60">
                            <i className="purple h4"><BiCurrentLocation size={32}/></i>
                            <p className="h5 mgL2"> ABUJA, NIGERIA.</p>
                        </div>

                    </div>
                    <div className="grid-item2_4 position_rel">
                        <motion.div  whileHover={{
                            scale: 1.2,
                            transition: { duration: 1 },

                        }} className="hero-circle position_rel ">
                            <GradientCircle className={""}/>
                        </motion.div>
                        <img src={avatar} alt="" className={"width60 avatar"}/>
                    </div>
            </div>
        </div>
    );
};

export default Hero;