import React, {useCallback, useEffect, useState} from 'react';
import Navigation from "../../components/navigation";
import Loader, {useLoading} from "../../components/Loader";
import AnimatedPage from "../../components/AnimatedPage";
import {Link, useParams} from "react-router-dom";
import AxiosInstance from "../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import Moment from "react-moment";
import Footer from "../../components/Footer";
import useScrollToTop from "../../helpers/UI/scrollToTop";
const FullProject = () => {
    const base = "";
    useScrollToTop()
    const {loading} = useLoading(2000)
    const [data,setData] = useState({
        cat:"",
        title:"",
        description:"",
        images: {},
        date: new Date(),
        challenge:"",
        context:"",
        paintP:"",
        feedback:"",
        result:""
    })
    const {id} = useParams()
    const colors = ["#1469e9","#40B672","#FEC32F","#4926AD"]

   const getData =  useCallback(()=>{
        AxiosInstance.get(`/port/p/${id}`)
            .then((res)=>{

                setData(res.data.data)
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    },[id]);

    useEffect(()=>{
        getData()
    },[getData])
    return (
        <div>
            <Navigation/>
            <ToastContainer/>
            {
                loading?<Loader title={"Loading."}/>:null
            }

         <AnimatedPage>
             <div className="center-all mgT12">
                 <div className="grid-">
                     <div className="grid-item2_4">
                         <h1 className="h1">{data?.title}.</h1>
                     </div>
                     <div className="grid-item2_4">
                         <h1 className="h4 medium purple">{data?.description}.</h1>
                         {
                          data?.live?.length>0?   <div className="mgT4">
                                 <a href={data.live} target={"_blank"} rel={"noreferrer"} className={'btn btn-grad'}>Live Site</a>
                             </div>:null
                         }
                     </div>
                 </div>

                 <div className=" mgT4">
                     <div className="grid-">
                         <div className="grid-item1_3 ">
                             <p className="h6 mgT2 purple ">ROLES/SERVICES</p>

                             <hr className="hr-sm"/>
                             <h5 className="h4 ">{data?.context}</h5>
                         </div>
                         <div className="grid-item1_3 ">
                             <p className="h6 mgT2 purple ">LOCATION</p>

                             <hr className="hr-sm"/>
                             <h5 className="h4 ">NIGERIA</h5>
                         </div>
                         <div className="grid-item1_3 ">
                             <p className="h6 mgT2 purple ">YEAR</p>

                             <hr className="hr-sm" />
                             <h5 className="h4 "><Moment format={"MMMM YYYY"}>{data?.date}</Moment>.</h5>
                         </div>
                     </div>
                 </div>

                 <div className="mgT8  darkCard">
                     <img src={`${base}/${data?.images?.primImg}`} className={'fluidImg'} alt=""/>
                 </div>
                 {/*<h5 className="h3 ">Context</h5>*/}
                 {/*<p className="h5 mgT2 purple">{data?.context}</p>*/}
                 <div className="mgT8  darkCard">
                     <img src={`${base}/${data?.images?.secImg}`} className={'fluidImg'} alt=""/>
                 </div>
               {/*<div className="mgT8 center-all width60">*/}
               {/*    <h5 className="h3 ">The Challenge</h5>*/}
               {/*    <p className="h5 mgT2 purple">{data?.challenge}</p>*/}
               {/*</div>*/}
                 <div className="mgT8">
                     <img src={`${base}/${data?.images?.challengeImg}`} className={'fluidImg'} alt=""/>
                 </div>
               {/*<div className="mgT8">*/}
               {/*    <h5 className="h3 ">The Feedback</h5>*/}
               {/*    <p className="h5 mgT2 purple">{data?.feedback}</p>*/}
               {/*</div>*/}
                 <div className="mgT8">
                     <img src={`${base}/${data?.images?.feedImg}`} className={'fluidImg'} alt=""/>
                 </div>

               {/*<div className="mgT8">*/}
               {/*    <h5 className="h3 "> Result</h5>*/}
               {/*    <p className="h5 mgT2 purple">{data?.result}</p>*/}
               {/*</div>*/}
                 <div className="mgT8">
                     <img src={`${base}/${data?.images?.resultImg}`} className={'fluidImg'} alt=""/>
                 </div>




             </div>
         </AnimatedPage>
            <Footer/>
        </div>
    );
};

export default FullProject;