import React from 'react';

const GradientCircle = (props) => {
    return (
        <svg {...props} className={props.className} viewBox="0 0 259 259" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M129.271 258.552C200.666 258.552 258.543 200.675 258.543 129.281C258.543 57.8861 200.666 0.00927734 129.271 0.00927734C57.8768 0.00927734 0 57.8861 0 129.281C0 200.675 57.8768 258.552 129.271 258.552ZM129.271 212.927C175.468 212.927 212.917 175.477 212.917 129.281C212.917 83.084 175.468 45.6343 129.271 45.6343C83.0747 45.6343 45.625 83.084 45.625 129.281C45.625 175.477 83.0747 212.927 129.271 212.927Z" fill="url(#paint0_linear_554_299)" fill-opacity="0.9"/>
            <defs>
                <linearGradient id="paint0_linear_554_299" x1="-26.0714" y1="234.652" x2="214.365" y2="127.47" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#2FC5F4"/>
                    <stop offset="0.616192" stopColor="#5C72FB" stopOpacity="0.799792"/>
                    <stop offset="1" stopColor="#7544FF" stopOpacity="0.69"/>
                </linearGradient>
            </defs>
        </svg>

    );
};

export default GradientCircle;