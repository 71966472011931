export function numberWithCommas(x) {
    if(Number(x)>0){
        return parseFloat(x).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

    }else{
      return  x
    }
}
export function buildFormData(formData,data,parentKey) {
    if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
    } else {
        const value = data === null ? "" : data;
        formData.append(parentKey, value)
    }
}