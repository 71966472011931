import React, {useEffect} from 'react';

import {motion,useViewportScroll,useTransform,useAnimation} from "framer-motion";
import {useInView} from 'react-intersection-observer';
const AnimatedText = ({children}) => {
    const controls = useAnimation();
    const { ref, inView } = useInView();
    const {scrollYProgress} = useViewportScroll();
    const scale= useTransform(scrollYProgress,[0,1],[0.8,1.1])
    const scaleBtn= useTransform(scrollYProgress,[0,1],[1,1.2])
    const transition = {
        duration:1,
        ease: [0.6,0.13,0.23,0.75]
    };

    useEffect(() => {
        console.log(inView)
        if (inView) {
            controls.start('visible').then(()=>{

                console.log('starting')
            });
        }
        if (!inView) {
            controls.start('hidden').then();
        }
    }, [controls, inView]);


    const container = {
        hidden:{
            opacity:1,scale:0,x:100
        },
        visible:{
            opacity:1,
            scale:1,
            x:0,
            transition:{
                delayChildren:0.3,
                staggerChildren:0.2
            }
        }
    }

    const item = {
        hidden:{
            y:20,opacity:0
        },
        visible:{
            y:0,
            opacity:1
        }
    }
    return (
        <motion.div    ref={ref}
                       variants={container}
                       animate={controls}>
            {
                children
            }
        </motion.div>
    );
};

export default AnimatedText;