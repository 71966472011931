
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import './sass/styles.scss';
import Home from "./pages/home";
import React, {useState} from "react";
import Projects from "./pages/portfolio/projects";
import Contact from "./pages/contact";
import Journey from "./pages/about/journey";
import {AnimatePresence} from "framer-motion";
import FullProject from "./pages/portfolio/fullProject";
import AddPort from "./pages/cms/portfolio/addPort";
import AllPort from "./pages/cms/portfolio/allPort";
import Auth from "./pages/cms/Auth";
import {UserProvider} from "./helpers/userProvider";
import PrivateRoute from "./helpers/privateRoute";

import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "./pages/ErrorPage";


function App() {
    const location = useLocation();
    const navigate = useNavigate();
    const [user,setUser] = useState(localStorage.getItem('oke__')||{});
    const [token,setToken] = useState(localStorage.getItem('oke_tk')||null);
    // const {isOnline} = IsOnline();
    const logOut  = ()=>{
        localStorage.clear();
        navigate('/',{replace:true});
    }
    function login(data,token) {
        setUser(data);
        setToken(token);
        localStorage.setItem('oke_tk',token)
        localStorage.setItem('oke__',JSON.stringify(data));
        setTimeout(()=>{ navigate('/allPort')},3000);
        //navigate to dashboard
    }
  return (
   <AnimatePresence initial exitBeforeEnter>
       <UserProvider.Provider value={{token,user,logOut: logOut,login: login}}>
           <Routes key={location.pathname} location={location}>


               <Route exact path='/' element={<Home/>}/>
               <Route exact path='/work' element={<Projects/>}/>
               <Route exact path='/work/:id' element={<FullProject/>}/>
               <Route exact path='/contact' element={<Contact/>}/>
               <Route exact path='/about' element={<Journey/>}/>
               <Route exact path='/auth' element={<Auth/>}/>
               <Route exact path='/allPort' element={<PrivateRoute><AllPort/></PrivateRoute>}/>
               <Route exact path='/add' element={<PrivateRoute><AddPort/></PrivateRoute>}/>
               <Route exact path='/*' element={<div>
                  <ErrorPage/>
               </div>}/>
           </Routes>
       </UserProvider.Provider>
   </AnimatePresence>
  );
}

export default App;
