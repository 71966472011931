import React, {useEffect, useState} from 'react';
import AnimatedPage from "../../../components/AnimatedPage";
import Navigation from "../../../components/navigation";
import Tab from "../../../components/tab";
import PortfolioCard from "./PortfolioCard";
import {Link} from "react-router-dom";
import {getAll} from "../../../helpers/api/portfolio";
import {BiMessage} from "react-icons/bi";
import Footer from "../../../components/Footer";

const base = "https://okechukwuomeh.xyz";
const AllPort = () => {

    const [index,setIndex] =useState(0);

    const [tabs] = useState([<AllProjects/>,<Design/>,<Development/>])

    return (
        <div>
            <AnimatedPage>
                <Navigation/>
                <div className="mgT8 center-all ">
                    <h3 className="h3 mgB4">All Works.</h3>
                    <div className="mgB4">
                        <Link to={'/add'} className="btn btn-grad">Add New</Link>
                    </div>
                 <div className="mgT8">
                     <Tab tabs={["All","Design","Development"]} onChange={setIndex} activeIndex={index} tabsBody={<>
                         {
                             tabs[index]
                         }
                     </>}/>
                 </div>
                </div>
                <Footer/>
            </AnimatedPage>
            
        </div>
    );
};


function AllProjects() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("All").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data.length>0?data.map((e)=>
                <PortfolioCard key={e._id} title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={"2021"}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                <p className="h2 purple"><BiMessage/></p>
                <p className="h5 mgT2 purple">Nothing Found</p>
            </div>
        }
    </div>
}

function Design() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("Design").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data.length>0?data.map((e)=>
                    <PortfolioCard key={e._id} title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={"2021"}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                    <p className="h2 purple"><BiMessage/></p>
                    <p className="h5 mgT2 purple">Nothing Found</p>
                </div>
        }
    </div>
}

function Development() {
    const [data,setData] = useState([])
    useEffect(()=>{
        getAll("Development").then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{

            })
    },[])
    return   <div className={"grid"}>
        {
            data.length>0?data.map((e)=>
                    <PortfolioCard key={e._id} title={e.title} img={`${base}/${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={"2021"}/>):
                <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                    <p className="h2 purple"><BiMessage/></p>
                    <p className="h5 mgT2 purple">Nothing Found</p>
                </div>
        }
    </div>
}


export default AllPort;