import React, {useContext, useState} from 'react';
import Navigation from "../../../components/navigation";
import AnimatedPage from "../../../components/AnimatedPage";
import FileInput from "../../../components/FileInput";
import AxiosInstance from "../../../helpers/axiosInstance";
import {UserProvider} from "../../../helpers/userProvider";
import Loader from "../../../components/Loader";
import {buildFormData} from "../../../helpers/misc";
import {toast, ToastContainer} from "react-toastify";

const AddPort = () => {
    const ctx = useContext(UserProvider);
    const [loading,setLoading] = useState(false);
    const [categories,setCategories] = useState(["Design","Dev"])
    const [data,setData] = useState({
        cat:"Dev",
        title:"",
        description:"",
        primImg: new File([],"",undefined)
        ,
        secImg:  new File([],"",undefined)
        ,
        date: new Date(),
        challenge:"",
        context:"",
        paintP:"",
        feedback:"",
        result:"",
        pImg: new File([],"",undefined)
        ,
        feedImg:new File([],"",undefined)
        ,
        challengeImg: new File([],"",undefined)
        ,
        resultImg: new File([],"",undefined)
    })
    function add(e) {
        e.preventDefault();
        setLoading(true);

        const d = new FormData();
        buildFormData(d,data)
        console.log(d)
        console.log(data)
        AxiosInstance.post('/port/create',d,{
            headers:{
                authorization:ctx.token
            }
        })
            .then(res=>{
                // console.log(res.data)
                toast.dark(res.data.msg)

                setLoading(false)
                setData({
                    title:"",
                    description:"",
                    primImg: new File([],"",undefined)
                    ,
                    secImg:  new File([],"",undefined)
                    ,
                    date: new Date(),
                    challenge:"",
                    context:"",
                    paintP:"",
                    feedback:"",
                    result:"",
                    pImg: new File([],"",undefined)
                    ,
                    feedImg:new File([],"",undefined)
                    ,
                    challengeImg: new File([],"",undefined)
                    ,
                    resultImg: new File([],"",undefined)
                })
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
               setLoading(false)
            })
    }
    return (
        <div>
            <Navigation/>
            <AnimatedPage>

             <ToastContainer/>
             {
                 loading?<Loader title={"Loading..."}/>:null
             }
             <div className="center-all width60 mgT12">
                 <form onSubmit={add} className="contact-form">
                     <h4 className="h3 mgB4">Add a new Work.</h4>
                     <div className="form-input-box ">
                         <select  id={'name'} value={data.cat || ""} onChange={e=>{
                             const old = {...data};
                             old.cat = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={"Category"}>
                             {
                                 categories.map(e=><option key={e}>{e}</option>)
                             }
                         </select>
                     </div>
                     <div className="form-input-box ">
                         <input type="date"  value={data.date || ""} onChange={e=>{
                             const old = {...data};
                             old.date = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={"Category"}/>
                     </div>
                     <div className="form-input-box ">
                         <input type="text" id={'name'} value={data.title || ""} onChange={e=>{
                             const old = {...data};
                             old.title = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={'Title'}/>
                     </div>
                     <div className="form-input-box ">
                         <textarea  id={'pass'}  value={data.description || ""} onChange={e=>{
                             const old = {...data};
                             old.description = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={'Description'}/>
                     </div>
                     <FileInput placeholder={"Primary Image"} type={"image"}
                                onFileSelected={(e)=>{
                                    let old = {...data};
                                    let files = e.target.files;
                                    old.primImg =  files[0]
                                    setData(old)
                                }}
                     />

                     <div className="form-input-box ">
                         <textarea  id={'name'} value={data.context || ""} onChange={e=>{
                             const old = {...data};
                             old.context = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={'Context'}/>
                     </div>
                     <FileInput placeholder={"Context Image"} type={"image"}
                                onFileSelected={(e)=>{
                                    let old = {...data};
                                    let files = e.target.files;
                                    old.secImg = files[0]
                                    setData(old)
                                }}
                     />
                        <div className="mgV4">
                            <p className="h6 mgB2">Challenge Section</p>
                            <FileInput placeholder={" Challenge Image"} type={"image"}
                             onFileSelected={(e)=>{
                                 let old = {...data};
                                 let files = e.target.files;
                                 old.challengeImg =  files[0]
                                setData(old)


                             }}
                            />
                            <div className="form-input-box mgT2">
                                <textarea  id={'name'} value={data.challenge || ""} onChange={e=>{
                                  const old = {...data};
                                  old.challenge = e.target.value;
                                   setData(old)
                                }} className={'form-input'} placeholder={'Challenge'}/>
                            </div>
                        </div>

                     <p className="h6 mgB2">Pain points Section</p>
                     <FileInput placeholder={" Pain points Image"} type={"image"}
                                onFileSelected={(e)=>{
                                    let old = {...data};
                                    let files = e.target.files;
                                    old.pImg = files[0]
                                    setData(old)
                                }}
                     />
                     <div className="form-input-box ">
                         <textarea  id={'name'} value={data.paintP || ""} onChange={e=>{
                             const old = {...data};
                             old.paintP = e.target.value;
                             setData(old)
                         }} className={'form-input'} placeholder={'Paint Points'}/>
                     </div>
                     <p className="h6 mgB2">Feedback</p>
                     <FileInput placeholder={" Feedback Image"} type={"image"}
                                onFileSelected={(e)=>{
                                    let old = {...data};
                                    let files = e.target.files;
                                    old.feedImg =  files[0]
                                    setData(old)
                                }}
                     />
                     <div className="form-input-box ">
                         <textarea  id={'name'} value={data.feedback || ""} onChange={e=>{
                             const old = {...data};
                             old.feedback = e.target.value;

                             setData(old)
                         }} className={'form-input'} placeholder={'Feedback'}/>
                     </div>

                     <p className="h6 mgB2">Results Section</p>
                     <FileInput placeholder={"Result Image"} type={"image"}
                                onFileSelected={(e)=>{
                                    let old = {...data};
                                    let files = e.target.files;
                                    old.resultImg =  files[0]
                                    setData(old)
                                }}
                     />
                     <div className="form-input-box ">
                         <textarea  id={'name'} value={data.result || ""} onChange={e=>{
                             const old = {...data};
                             old.result = e.target.value;

                             setData(old)
                         }} className={'form-input'} placeholder={'Result'}/>
                     </div>


                     <button className="btn-outlined btn-outlined-purple mgT2">
                         Add &#43;
                     </button>
                 </form>



             </div>
         </AnimatedPage>
        </div>
    );
};

export default AddPort;