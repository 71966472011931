import React from 'react';
import Moment from "react-moment";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <div className={"footer mgT12"}>
          <div className="center-all ">
              <div className="grid ">
                  <div className="grid-item2_4">
                      <h2 className={'h2 '}>Let's Work Together.</h2>
                  </div>
                  <div className="text_left_mob">
                      <Link className={"btn-outlined btn-outlined-purple"} to={'/contact'}>Reach Out </Link>
                  </div>
              </div>
              <hr className="hr"/>
              <div className="grid mgT8">
                  <div className="grid-item1_4">
                      <h6 className="h7 purple "> LOCAL TIME </h6>
                      <p className={"h5 mgT2"}>
                          <Moment  format={"MMM DD YYYY hh : mm"}/></p>
                  </div>
                  <div className="grid-item1_4">
                      <h6 className="h7 purple "> CONTACT ME</h6>
                      <p className={"h5 mgT2"}>info@okechukwuomeh.xyz</p>
                  </div>
                  <div className="grid-item1_4">
                      <h6 className="h7 purple "> DESIGNED & CODED BY ME</h6>
                      <Link to={'/auth'} className={"h5 mgT2 no-deco whiteText"}>&rarr;</Link>
                  </div>
              </div>
          </div>
            
        </div>
    );
};

export default Footer;