import React from 'react';

const PortfolioCard = ({img,title,link, cat}) => {
    return (
        <div className="project_alt ">
            <div className="project-img_alt">
                {img?<img src={img} alt="" className={"fluidImg"}/>:null}
            </div>
            <div className="project_alt_bottom">

           <div className="">
               <h2 className="h4 mgT2">{title}</h2>
               <div className="project_alt-view">

                   <p className="h5 purple">{cat}</p>


               </div>
               <div className="mgT2">
                   <button className="btn btn-grad">Edit</button>
                   <button className="btn btn-red mgL2">Delete</button>
               </div>

           </div>
        </div>

        </div>
    );
};

export default PortfolioCard;