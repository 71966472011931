import React from 'react';
import Navigation from "../../components/navigation";
import {FaSass,FaJs, FaReact, FaNodeJs,FaVuejs,FaFigma} from 'react-icons/fa'
import {SiAdobeillustrator} from 'react-icons/si'
import FlutterIcon from "../../img/Flutter"
import about from '../../assets/ab1.jpg'
import What from "./What";
import Footer from "../../components/Footer";
import Loader, {useLoading} from "../../components/Loader";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import AnimatedPage from "../../components/AnimatedPage";
function Journey() {
    const {loading} = useLoading(2000)
    useScrollToTop()
    return (
        <>
            {
                loading?<Loader title={"About."}/>:null
            }
            <AnimatedPage ><div className='dampen'>
                <Navigation/>

                <section className="cv-section">
                    {/*<h2 className={'h2'}>Helping brands Grow.</h2>*/}
                    <div className=" mgT4 grid pdV2 about--section darkCard">
                        <div className="about--section-circle about--section-circle-bottom-left">
                            <div className="about--section-circle-inner">
                                <p className="h2">&darr;</p>
                            </div>
                        </div>
                        <div className="grid-item2_4 ">
                            <p className="bld h2">Hallo,</p>
                            <p className="h4 mgT4 ql-color-blue " >I'm a UI designer and software developer primarily focused on web & mobile platforms. I have over four years of experience working in various areas of design and development.</p>
<hr className="hr"/>

                             <p className="h4 mgT2 greyText ">
                                 My experience spans multiple roles including; print design, web and app design, web development, mobile app development, design-thinking, experimentation and front-end implementation. I have a background in graphic design, and software development and a degree in mechanical engineering.</p>
                        </div>
                        <div className="grid-item2_4 mgL2max">

                            <div className="about--section-img mgT2"  style={{backgroundImage: `url(${about})`}}>
                            </div>


                        </div>
                    </div>
                    <What/>
                    <div className="my-stack mgT12">
                        <h2 className="h3">
                            What I work with.
                        </h2>
                        <div className="my-stack-carousel mgT4">
                            <div className="stack-item">
                                <div className="animatedCircle nodejs">
                                    <FaNodeJs  className='stack-icon  ' />
                                </div>

                                <h2 className='h6'> NodeJs</h2>
                            </div>
                            <div className="stack-item">
                                <div className="animatedCircle sass">
                                    <FaSass color='#f7f7f7' className='stack-icon'/>
                                </div>

                                <h2 className='h6'>SASS  </h2>
                            </div>
                            <div className="stack-item">
                                <div className="animatedCircle js">
                                    <FaJs className='stack-icon'  />
                                </div>

                                <h2 className='h6'> Javascript</h2>
                            </div>
                            <div className="stack-item">
                                <div className="animatedCircle react">
                                    <FaReact className='stack-icon'  />
                                </div>

                                <h2 className='h6'> React JS</h2>
                            </div>
                            <div className="stack-item">
                                <div className="animatedCircle vue">
                                    <FaVuejs className='stack-icon'  />
                                </div>

                                <h2 className='h6'> Vue JS</h2>
                            </div>
                            <div className="stack-item" style={{ color: "#1c7fff"}}>
                                <div className="animatedCircle " style={{ color: "#1c7fff"}}>
                                    <FlutterIcon className='stack-icon flutter'  />
                                </div>

                                <h2 className='h6'> Flutter</h2>
                            </div>
                            <div className="stack-item" style={{ color: "#f01cff"}}>
                                <div className="animatedCircle " style={{ color: "#f01cff"}}>
                                    <FaFigma className='stack-icon'  />
                                </div>

                                <h2 className='h6'> Figma</h2>
                            </div>
                            <div className="stack-item" style={{ color: "#ff8e1c"}}>
                                <div className="animatedCircle " style={{ color: "#ff8e1c"}}>
                                    <SiAdobeillustrator className='stack-icon'  />
                                </div>

                                <h2 className='h6'>Adobe Illustrator</h2>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<SocialIconBox/>*/}

            </div>

                <Footer/>
            </AnimatedPage>
        </>


    )
}

export default Journey;