import React, {useContext, useState} from 'react';
import Navigation from "../../components/navigation";
import AxiosInstance from "../../helpers/axiosInstance";
import {toast, ToastContainer} from "react-toastify";
import AnimatedPage from "../../components/AnimatedPage";
import {UserProvider} from "../../helpers/userProvider";
import Loader, {useLoading} from "../../components/Loader";

const Auth = () => {
    const [index,setIndex] =useState(0);
    const [secret,setSecret] = useState("")
    const [password,setPassword] = useState("");
   const [loading,setLoading] = useState(false)
    const ctx = useContext(UserProvider)
    function login(e) {
       e.preventDefault();
       setLoading(true)
        AxiosInstance.post('/auth/login',{secret,password})
            .then(res=>{
                toast.dark(res.data.msg);
                setLoading(false)
                setTimeout(()=>{
                    setIndex(0);
                    ctx.login(res.data,res.data.token)
                },1000)

            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    function reg(e) {
        e.preventDefault();
        setLoading(true)
        AxiosInstance.post('/auth/reg',{secret,password})
            .then(res=>{
                toast.dark(res.data.msg);
                setLoading(false)
                setTimeout(()=>{
                    setIndex(0)
                },1000)

            })
            .catch(err=>{
                setLoading(false)
                toast.error(err.response.data.msg)
            })
    }
    return (
        <div className={''}>
          <AnimatedPage>
              <Navigation/>
              {
                  loading?<Loader title={"Loading..."}/>:null
              }
              <ToastContainer/>
              {
                  index===0?
                      <div className="center-all width45 mgT12">
                      <form onSubmit={login} className="contact-form">
                          <h4 className="h4 mgB4">Login</h4>
                          <label htmlFor={'name'} className="h6 whiteText">SECRET</label>
                          <div className="form-input-box ">
                              <input type="text" id={'name'} value={secret} onChange={e=>setSecret(e.target.value)} className={'form-input'} placeholder={'Your Secret'}/>
                          </div>
                          <label htmlFor={'pass'} className="h6 whiteText">PASSWORD</label>
                          <div className="form-input-box ">
                              <input type="text" id={'pass'}  value={password} onChange={e=>setPassword(e.target.value)} className={'form-input'} placeholder={'Your Password'}/>
                          </div>


                          <button className="btn-outlined btn-outlined-purple mgT2">
                              Login &rarr;
                          </button>
                          <hr className="hr"/>
                          <p className="link h6 purple" onClick={()=>setIndex(1)}>Register</p>
                      </form>



                  </div>:  <div className="center-all width45 mgT12">
                      <form onSubmit={reg} className="contact-form">
                          <h4 className="h4 mgB4">Register</h4>
                          <label htmlFor={'name'} className="h6 whiteText">SECRET</label>
                          <div className="form-input-box ">
                              <input type="text" id={'name'} value={secret} onChange={e=>setSecret(e.target.value)} className={'form-input'} placeholder={'Your Secret'}/>
                          </div>
                          <label htmlFor={'pass'} className="h6 whiteText">PASSWORD</label>
                          <div className="form-input-box ">
                              <input type="text" id={'pass'}  value={password} onChange={e=>setPassword(e.target.value)} className={'form-input'} placeholder={'Your Password'}/>
                          </div>


                          <button className="btn-outlined btn-outlined-purple mgT2">
                              Register &rarr;
                          </button>
                          <hr className="hr"/>
                          <p className="link h6 purple" onClick={()=>setIndex(0)}>Login</p>
                      </form>



                  </div>
              }
          </AnimatedPage>
        </div>
 );

};

export default Auth;