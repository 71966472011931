import React from 'react';
import {motion} from "framer-motion";

const animations = {
    initial:{
        opacity: 0,y:100
    },
    animate:{
        opacity: 1,y:0
    },
    exit:{
        opacity:0, y:100,
        transition: {
            delay: .3
        }
    },
    transition:{
        duration: .5,
        delay: 1.25
    }
}
const AnimatedPage = ({children}) => {

    return (
        <motion.div {...animations} style={{overflowX:"hidden"}}>
            {children}
        </motion.div>
    );
};

export default AnimatedPage;