import React from 'react';

const What = () => {
    return (
        <div className={'what--section  mgT12'}>
            <h2 className="h2 ">What I Offer</h2>
            <div className="grid mgT8 darkCard">
                <div className="grid-item1_3 ">
                    <p className="h6 mgT2 purple bld ">01</p>

                    <hr className="hr-sm"/>
                    <h5 className="h4 ">DESIGN</h5>
                    <p className="h5 mgT4">With a sound track record in designing websites and apps, I deliver beautiful, strong and user-friendly digital designs. </p>
                </div>
                <div className="grid-item1_3 ">
                    <p className="h6 mgT2 purple bld ">02</p>

                    <hr className="hr-sm"/>
                    <h5 className="h4 ">DEVELOPMENT</h5>
                    <p className="h5 mgT4">I build scalable websites and apps from scratch that fit seamlessly with the design. I always deliver on time.</p>
                </div>
                <div className="grid-item1_3 ">
                    <p className="h6 mgT2 purple bld ">03</p>

                    <hr className="hr-sm"/>
                    <h5 className="h4 ">Everything You Need</h5>
                    <p className="h5 mgT4">I have worked with various companies taking their products from design stages to working prototypes.</p>
                </div>
            </div>
        </div>
    );
};

export default What;