import React, {useState} from 'react';
import Navigation from "../components/navigation";
import Loader, {useLoading} from "../components/Loader";
import useScrollToTop from "../helpers/UI/scrollToTop";
import AnimatedPage from "../components/AnimatedPage";
import {contactMe} from "../helpers/api/portfolio";
import {toast, ToastContainer} from "react-toastify";

function Contact() {
    const {loading} = useLoading(3000)
    useScrollToTop();
    const [data,setData] = useState({
        name:"",
        email:"",
        msg:""
    });

    function contact(e) {
        e.preventDefault()
        contactMe(data)
            .then(res=>{
                toast.dark(res.data.msg);
                setData({
                    name:"",
                    email:"",
                    msg:""
                })
            })
            .catch(err=>{
                toast.error(err.response.data.msg)
            })
    }
    return (
        <div> {
            loading?<Loader title={"Reach Out."}/>:null
        }
        <ToastContainer/>
           <AnimatedPage>
               <Navigation/>

               <form onSubmit={contact}  className="center-all ">
                   <div className="grid mgT8">
                       <div className="grid-item2_4">
                           <h2 className={'h2 '}>Let's Work Together.</h2>
                       </div>
                       <div className=""></div>
                   </div>
                   <hr className="hr"/>
                   <div className="mgT12  grid phone-ro-rev">

                       <div className="grid-item3_4">
                           <div className="contact-form">
                               <div className="grid- mgB2 fl-c-t">
                                   <div className="grid-item2_4">
                                       <label htmlFor={'name'} className="h6 whiteText">YOUR NAME</label>
                                       <div className="form-input-box ">
                                           <input type="text" id={'name'} value={data.name||""} onChange={e=>{
                                               let old = {...data};
                                               old.name = e.target.value;
                                               setData(old)
                                           }} className={'form-input'} placeholder={'Your Name'}/>
                                       </div>
                                   </div>
                                   <div className="grid-item2_4">
                                       <label htmlFor={'email'} className="h6 whiteText">YOUR EMAIL</label>
                                       <div className="form-input-box ">
                                           <input type="text" id={'email'}  value={data.email||""} onChange={e=>{
                                               let old = {...data};
                                               old.email = e.target.value;
                                               setData(old)
                                           }}  className={'form-input'} placeholder={'Your Email'}/>
                                       </div>
                                   </div>
                               </div>
                               <div className="grid mgT4"> <div className="grid-item2_4">
                                   <label htmlFor={'msg'} className="h7 whiteText">MESSAGE</label>
                                   <div className="form-input-box ">
                                       <textarea  id={'msg'}  value={data.msg||""} onChange={e=>{
                                               let old = {...data};
                                               old.msg = e.target.value;
                                               setData(old)
                                           }}  className={'form-textArea'} placeholder={'Your Name'} style={{height:"10rem"}}/>
                                   </div>
                               </div>

                               </div>

                               <button className="btn-outlined btn-outlined-purple mgT2">
                                   Contact Us
                               </button>
                           </div>
                       </div>
                       <div className="grid-item1_4 mgL2max">
                           <section className="">
                               <h6 className="h7 purple "> CONTACT INFO </h6>
                               <p className={"h5 mgT2"}>info@okechukwuomeh.xyz</p>
                               <p className={"h5 mgT2"}> +234 811 545 1846</p>

                           </section>
                           <section className="mgT8">
                               <h6 className="h7 purple "> SOCIALS </h6>
                               <a href={'https://linkedin.com/in/omeh'} target={"_blank"} rel={"noreferrer"} className={"h5 no-deco mgT2 whiteText "} style={{display:"block"}}>LinkedIn</a>
                               <a href={'https://dribbble.com/OmehSam'} target={"_blank"} rel={"noreferrer"} className={"h5 no-deco mgT2 whiteText "} style={{display:"block"}}>Dribbble</a>
                               <a href={'https://github.com/NEXTink'} target={"_blank"} rel={"noreferrer"} className={"h5 no-deco mgT2 whiteText "} style={{display:"block"}}>Github</a>

                           </section>
                       </div>
                   </div>
               </form>
               {/*<SocialIconBox/>*/}

               {/*<Footer/>*/}
           </AnimatedPage>
        </div>
    )
}

export default Contact;