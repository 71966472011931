import React, { useEffect, useRef, useState} from 'react';
import {BiFile, BiImage} from "react-icons/bi";

const FileInput = ({onFileSelected,reset,type,multi,placeholder}) => {
    const fileRef = useRef(null)
    const [url,setUrl] = useState([""])
    const [type_] = useState(type || "image")
    // const [file,setFile] = useState(undefined)
    const handleFileInput =(e)=>{

        // setFile(e.target.files[0])
        let urls = []
        if(e.target.files.length>0){
            for (let i=0;i<e.target.files.length;i++){
                let url_ = getUrlImg(e.target.files[0])
                urls.push(url_)
            }
            setUrl(urls);
        }
        onFileSelected(e)
    }
    function getUrlImg(file) {
      return  URL.createObjectURL(file)

    }
    useEffect(()=>{
        fileRef.current.value = null;
        setUrl([])
    },[reset])
    return (
        <div className="form-file" onClick={()=>fileRef.current && fileRef.current.click()}>

            <div className={'form_img'}>{
                url.length >0 && type_ === "image"?url.map((e,i)=><img alt={'file input'} key={i} className={'fluidImg'} src={e}/>):<div className={'centerText'}>

                    {type==="image" && url.length<=0?<BiImage size={28}/>:<BiFile size={28}/>}
                </div>
            }</div>
            <input maxLength={3} multiple={multi} type="file" ref={fileRef}  onChange={handleFileInput} className="file-input"  />
            {url.length===0?<h6 className="h6 mgL2">{placeholder}</h6>:null}
        </div>
    );
};

export default FileInput;