import AxiosInstance from "../axiosInstance";


export async function getAll(cat) {
     return await  AxiosInstance.get(`/port/p/all/${cat}`)
}


export async function getHome() {
     return await  AxiosInstance.get(`/port/p/home/`)
}
export async function contactMe(data) {
     return await  AxiosInstance.post(`/port/contact/`,data)
}

