import React,{useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ProjectCard from "../portfolio/ProjectCard";
import { getHome} from "../../helpers/api/portfolio";
import {BiMessage} from "react-icons/bi";
import moment from "moment";

const ProjectsOverview = () => {
    const base = "";
    const [data,setData] = useState([])
    useEffect(()=>{
        getHome().then(res=>{
            setData(res.data.data)
        })
            .catch(err=>{
                console.log(err)
            })
    },[])
    return (
        <div className={'projectsOverview'}>
            <div className="center-all">
              <h4 className="h3">Featured Work.</h4>
                <div className={"grid mgT4"}>
                    {
                        data?.length>0?data.map((e)=> <ProjectCard key={e._id} title={e.title} img={`${base}${e?.images?.primImg}`} link={`/work/${e._id}`} cat={e?.cat} date={moment(e?.date).format("YYYY")}/>):
                            <div className={"display_flex just-centre align-center width100"} style={{flexDirection:"column"}}>
                                <p className="h2 purple"><BiMessage/></p>
                                <p className="h5 mgT2 purple">Nothing Found</p>
                            </div>
                    }   </div>


                <Link to={'/work'} className="btn-outlined btn-outlined-purple mgT8">
                    More Work &rarr;
                </Link>

            </div>

        </div>
    );
};

export default ProjectsOverview;