import React from 'react';
let userDetails ={
    token: String,
    user: Object || String,
    logOut:Function,
    login: Function,
}


export const UserProvider = React.createContext(userDetails)

