import React,{useEffect, useState} from 'react';
import {motion} from "framer-motion";
// import bg from '../assets/loader.png'
const Loader = ({title}) => {

    const transition = {
        delay: 1.2,
        duration: 1.5
    }
    return <motion.div initial={{ opacity: 1}}
                       animate={{ opacity: .1}}
                       transition={transition}
                       exit={{ opacity: 0, scale: 7 }} className={'loader-cont'}>
        <motion.h5 layout initial={{ opacity: 1,  rotate:360}}
                   animate={{ opacity: .2,rotate:0}}
                   transition={{delay:.2,duration:1.5}}
                   exit={{ opacity: 0, scale: 2000 }} className="h3  circle-h">{title}</motion.h5>
    </motion.div>
};


 export function useLoading(time) {
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        setTimeout(()=>{
            setLoading(false)
        },time)
    },[time])

    return {
        loading, setLoading
    }
}

export default Loader;