import React from 'react';
import '../sass/components/tab.scss';
import {motion} from "framer-motion";

// const animations = {
//     initial:{
//         opacity: 0,y:100
//     },
//     animate:{
//         opacity: 1,y:0
//     },
//     exit:{
//         opacity:0, y:100,
//     },
//     transition:{
//         duration: .5
//     }
// }
const Tab = ({tabs,tabsBody,activeIndex,onChange}) => {

    return (
        <motion.div className={'tabs'} >
            <div className="tabs__top">
                {tabs.length>0?tabs.map((e,i)=><span onClick={()=>onChange(i)} key={i} className={activeIndex===i?"tabs__top-item tabs__top-item-active":"tabs__top-item"}>{e}</span>):null}
            </div>

                {tabsBody?<>
                <motion.div className="tabs__body" >
                    {tabsBody}
                </motion.div>
                </>:<><h6 className="h6 centerText"> Nothing Found</h6></>}

        </motion.div>
    );
};
export default Tab;