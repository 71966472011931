import React from 'react';
import Navigation from "../../components/navigation";
import Hero from "./hero";
// import Testimonials from "./testimonials";
import ProjectsOverview from "./ProjectsOverview";
import Footer from "../../components/Footer";
import {Link} from "react-router-dom";
import Loader,{useLoading} from "../../components/Loader";
import useScrollToTop from "../../helpers/UI/scrollToTop";
import AnimatedPage from "../../components/AnimatedPage";
function Home() {
const {loading} = useLoading(3000)
    useScrollToTop()
    return (
        <>
            <div>

                {
                    loading?<Loader title={"Home."}/>:null
                }
              <AnimatedPage>
                  <Navigation/>
                  <Hero/>

                  <hr className="hr"/>

                  <div className="darkCard_">
                      <div className="center-all  mgT12 grid 40 position_rel" >
                          <div className="grid-item2_4">
                              <p className="h3 medium purple width80 ">
                                  Helping Brands Exceed Expectations. </p>
                          </div>
                          <div className="grid-item1_4  ">
                              <p className="h5 mgR-1"> "Simple Designs Solve
                                  Complex Problems "- OMEH</p>
                              <h6 className="h5 purple mgT2">ABOUT ME</h6>
                              <div className="about--section-circle about--section-circle-bottom-right" title={"About Okechukwu"}>
                                  <Link to={'/about'} className="about--section-circle-inner no-deco">
                                      <p className="h2"> &darr;</p>
                                  </Link>
                              </div>
                          </div>
                      </div>
                  </div>
                  <ProjectsOverview/>
                  {/*<Testimonials/>*/}
                  {/*<SocialIconBox/>*/}
                  <Footer/>
              </AnimatedPage>
            </div>
        </>
    )
}

export default Home;